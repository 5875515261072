import { capitalize } from "lodash-es"

import { getProducts as getProductsLemonSqueezy } from "@/helpers/products/lemon-squeezy"
import { getProducts as getProductsPolar } from "@/helpers/products/polar"

type Provider = "lemon_squeezy" | "polar"

export function getProducts(app: App, provider: Provider = "lemon_squeezy") {
  if (provider === "lemon_squeezy") {
    return getProductsLemonSqueezy(app)
  }

  if (provider === "polar") {
    return getProductsPolar(app)
  }
}

export function getLimits(app: App) {
  if (app === "ycd") {
    return {
      guest: {
        perDownload: Infinity,
        perMonth: Infinity,
      },

      hobby: {
        perDownload: Infinity,
        perMonth: 500_000,
      },
      pro: {
        perDownload: Infinity,
        perMonth: 10_000_000,
      },
      business: {
        perDownload: Infinity,
        perMonth: 100_000_000,
      },

      subscription: {
        perDownload: Infinity,
        perMonth: 1_000_000,
      },

      prepaid: {
        perDownload: Infinity,
        perMonth: Infinity,
      },

      lifetime: {
        perDownload: Infinity,
        perMonth: Infinity,
      },

      "100000": {
        perDownload: Infinity,
        perMonth: 100_000,
      },
      "250000": {
        perDownload: Infinity,
        perMonth: 250_000,
      },
      "500000": {
        perDownload: Infinity,
        perMonth: 500_000,
      },
      "1000000": {
        perDownload: Infinity,
        perMonth: 1_000_000,
      },
      "3000000": {
        perDownload: Infinity,
        perMonth: 3_000_000,
      },
      "10000000": {
        perDownload: Infinity,
        perMonth: 10_000_000,
      },
    }
  }

  if (app === "ct") {
    return {
      guest: {
        perDownload: Infinity,
        perMonth: 10000,
      },

      subscription: {
        perDownload: Infinity,
        perMonth: Infinity,
      },

      prepaid: {
        perDownload: Infinity,
        perMonth: Infinity,
      },
    }
  }
}

export function getSubscriptionTiers(
  app: App,
  provider: Provider = "lemon_squeezy",
) {
  const products = getProducts(app, provider)

  if (app === "ct") {
    return ["subscription"]
  }

  if (app === "ycd") {
    return [
      "hobby",
      "pro",
      "business",
      "subscription",
      ...Object.keys(products.subscriptions).map((key) => String(key)),
    ]
  }
}

export function getTierLabel(
  app: App,
  tier: string,
  provider: Provider = "lemon_squeezy",
) {
  const products = getProducts(app, provider)

  if (app === "ct") {
    return capitalize(tier)
  }

  if (app === "ycd") {
    return (
      (Object.keys(products.subscriptions).find(
        (key) => String(key) === tier,
      ) &&
        "subscription") ||
      capitalize(tier)
    )
  }
}

export function getStatusLabel(status: string) {
  switch (status) {
    case "active":
      return "Active"
    case "canceled":
      return "Canceled"
    case "expired":
      return "Expired"
    case "past_due":
      return "Past due"
    case "unpaid":
      return "Unpaid"
    default:
      return status
  }
}

export function isPrepaidTier(tier: string) {
  return ["guest", "prepaid"].includes(tier)
}
