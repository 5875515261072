export function relativeDate(targetDate) {
  const now = Date.now()
  const target = new Date(targetDate).getTime()

  const diff = Math.abs(target - now)

  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) return `in ${days} days`
  else if (hours > 0) return `in ${hours} hours`
  else if (minutes > 0) return `in ${minutes} minutes`
  else return `in ${seconds} seconds`
}
