export function parseCustomList(url: string) {
  return [
    ...new Set(
      url
        .split(/\s|,/g)
        .map((url) => url.trim())
        .filter((url) => url !== "")
        .map((url) =>
          !url.startsWith("https://") && !url.startsWith("http://")
            ? `https://${url}`
            : url,
        ),
    ),
  ]
}

export function parseContentUrl(inputUrl: string, type: string) {
  if (type === "custom-list") {
    return inputUrl
  }

  if (!inputUrl || inputUrl === "") {
    return false
  }

  if (!inputUrl.startsWith("https://") && !inputUrl.startsWith("http://")) {
    inputUrl = `https://${inputUrl}`
  }

  try {
    const url = new URL(inputUrl)
    let contentId: string = ""

    if (
      !["youtu.be", "youtube.com", "www.youtube.com", "m.youtube.com"].includes(
        url.host,
      )
    ) {
      return false
    }

    if (["video", "live"].includes(type)) {
      if (url.host === "youtu.be") {
        contentId = url.pathname.slice(1)
      }

      if (
        ["youtube.com", "www.youtube.com", "m.youtube.com"].includes(url.host)
      ) {
        if (url.pathname === "/watch") {
          contentId = url.searchParams.get("v") || ""
        }

        if (url.pathname.slice(0, 6) === "/embed") {
          contentId = url.pathname.slice(7)
        }

        if (url.pathname.slice(0, 3) === "/v/") {
          contentId = url.pathname.slice(3)
        }

        if (url.pathname.slice(0, 6) === "/live/") {
          contentId = url.pathname.slice(6)
        }
      }

      if (!contentId || contentId.length !== 11) {
        return false
      }

      return contentId
    }

    if (["community", "community-images"].includes(type)) {
      if (url.pathname.startsWith("/post/")) {
        contentId = url.pathname.slice(6)
      }

      if (
        url.pathname.startsWith("/channel/") &&
        url.pathname.endsWith("/community") &&
        url.searchParams.has("lb")
      ) {
        contentId = url.searchParams.get("lb") || ""
      }

      if (!contentId || contentId.length < 26) {
        return false
      }

      return contentId
    }

    if (type === "short") {
      if (url.pathname.startsWith("/shorts/")) {
        contentId = url.pathname.slice(8)
      }

      if (url.pathname === "/watch") {
        contentId = url.searchParams.get("v") || ""
      }

      if (url.pathname.slice(0, 3) === "/v/") {
        contentId = url.pathname.slice(3)
      }

      if (url.pathname.slice(0, 6) === "/embed") {
        contentId = url.pathname.slice(7)
      }

      if (!contentId || contentId.length !== 11) {
        return false
      }

      return contentId
    }

    if (type === "playlist") {
      if (url.pathname.startsWith("/playlist")) {
        contentId = url.searchParams.get("list") || ""
      }

      if (!contentId || contentId.length < 13) {
        return false
      }

      return contentId
    }

    if (type === "channel") {
      if (url.pathname.startsWith("/@")) {
        contentId = url.pathname.split("/")[1]
      }

      if (url.pathname.startsWith("/channel/")) {
        const split = url.pathname.split("/")
        contentId = `${split[1]}/${split[2]}`
      }

      if (!contentId) {
        return false
      }

      return contentId
    }

    return false
  } catch (error) {
    return false
  }
}
